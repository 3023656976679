@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Rajdhani;
  src: url(./fonts/Rajdhani-Medium.ttf);
}

body {
  font-family: Rajdhani;
}

body::-webkit-scrollbar {
  width: 8px;
  cursor: pointer;
}
body::-webkit-scrollbar-track {
  background-color: rgba(229, 231, 235, var(--bg-opacity));
  cursor: pointer;
}
body::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #a0aec0;
}
